/* You can add global styles to this file, and also import other style files */
body {
  font-family: Montserrat, Arial, sans-serif;
  background-color: #eee;
}

.grecaptcha-badge {
  width: 70px !important;
  overflow: hidden !important;
  transition: all 0.3s ease !important;
  left: 0 !important;
  bottom: 45px !important;
}

.grecaptcha-badge:hover {
  width: 256px !important;
}

.cc-window {
  box-shadow:
    0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.no-margin-bottom {
  .mat-checkbox-layout {
    margin-bottom: 0 !important;
  }
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #121d43;

  a {
    color: #121d43;
  }

  .mat-icon.lens {
    font-size: 0.5em;
    height: 0.5em;
    line-height: 0.5em;
    margin-left: 0.5em;
    margin-right: 0.5em;
  }

  .flag-fr {
    margin-right: 0.5em;
  }

  .mat-form-field-infix {
    width: auto;
  }
}

.feedback {
  position: fixed;
  bottom: 142px;
  right: 0;
  text-align: right;
  padding: 20px;
}

.help,
.mobile-footer {
  position: fixed;
  bottom: 71px;
  right: 0;
  text-align: right;
  padding: 20px;
}

.mobile-footer {
  display: none;
  z-index: 999;
}

.menu-button {
  transition: 300ms ease-in-out;
  transform: rotate(0deg);
}
.menu-button.rotated {
  transform: rotate(180deg);
}

#Smallchat iframe {
  z-index: unset !important;
}

.submenu {
  overflow-y: hidden;
  padding-left: 30px;
  max-height: 0;
  transition: max-height 300ms ease-in;
}
.submenu.expanded {
  max-height: 100px;
  transition: max-height 300ms ease-out;
}

.subscribe {
  mat-checkbox {
    label.mat-checkbox-layout {
      white-space: normal;
    }
  }
  mat-radio-button {
    label.mat-radio-label {
      white-space: normal;
    }
  }
}

.individual-or-enterprise {
  .mat-slide-toggle-content {
    white-space: normal;
  }
}

@media screen and (min-width: 1600px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1440px;
  }
}

@media screen and (max-width: 992px) {
  .mobile-footer {
    display: block;
  }
  .footer,
  .feedback,
  .help {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .feedback,
  .help,
  .mobile-footer {
    padding: 10px;
  }
}

.msg-error {
  background-color: #f44336;
  color: #611a15;
  .mat-simple-snackbar-action {
    color: #611a15;
  }
}

.msg-success {
  background-color: #d4edda;
  color: #545e57;
  .mat-simple-snackbar-action {
    color: #545e57;
  }
}

.msg-warning {
  background-color: #fff3cd;
  color: #666152;
  .mat-simple-snackbar-action {
    color: #666152;
  }
}

.msg-info {
  color: rgba(255, 255, 255, 0.8);
  .mat-simple-snackbar-action {
    color: rgba(255, 255, 255, 0.8);
  }
}

.mat-radio-button.no-label .mat-radio-label-content {
  padding-left: 0px !important;
}

.mat-bottom-sheet-container .mat-form-field-wrapper {
  padding-bottom: 0 !important;
}

.notification-center-toggler > * {
  pointer-events: none;
}

.notification-center > * {
  pointer-events: none;
}

.notification-center a {
  pointer-events: all;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

label {
  margin-bottom: 0;
}

.mat-menu-nowrap.mat-menu-panel {
  max-width: initial !important;
}

.mat-button-end-icon {
  display: flex !important;
  align-items: center;
  span {
    margin-right: 16px;
  }
  .mat-icon {
    margin-left: auto;
    margin-right: 0px !important;
  }
}

.icon-tooltip {
  height: 1.66em !important;
  width: 1.66em !important;
  line-height: 1.66em !important;
  .mat-icon {
    font-size: 1em;
    height: 1em;
    width: 1em;
    line-height: 1em !important;
  }
}

.multiline-tooltip {
  &.mat-tooltip {
    white-space: pre-line;
  }
}
