@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!

// Define a custom typography config that overrides the font-family as well as the
// `headlines` and `body-1` levels.
$custom-typography: mat.define-legacy-typography-config(
  $font-family: 'Montserrat, Roboto, Arial, monospace',
);

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($custom-typography);`
@include mat.all-legacy-component-typographies($custom-typography);
@include mat.legacy-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$contrast: (
  50: rgba(black, 0.87),
  100: rgba(black, 0.87),
  200: rgba(black, 0.87),
  300: rgba(black, 0.87),
  400: rgba(black, 0.87),
  500: white,
  600: white,
  700: white,
  800: white,
  900: white,
  A100: rgba(black, 0.87),
  A200: white,
  A400: white,
  A700: white,
);

$parsec-darkblue: (
  50: #0c429c,
  100: #0c429c,
  200: #0c429c,
  300: #0c429c,
  400: #0c429c,
  500: #0c429c,
  600: #0c429c,
  700: #0c429c,
  800: #0c429c,
  900: #0c429c,
  A100: #0c429c,
  A200: #0c429c,
  A400: #0c429c,
  A700: #0c429c,
  contrast: $contrast,
);

$parsec-lightblue: (
  50: #121d43,
  100: #121d43,
  200: #121d43,
  300: #121d43,
  400: #121d43,
  500: #121d43,
  600: #121d43,
  700: #121d43,
  800: #121d43,
  900: #121d43,
  A100: #121d43,
  A200: #121d43,
  A400: #121d43,
  A700: #121d43,
  contrast: $contrast,
);

$parsec-darkgrey: (
  50: #2e2e2e,
  100: #2e2e2e,
  200: #2e2e2e,
  300: #2e2e2e,
  400: #2e2e2e,
  500: #2e2e2e,
  600: #2e2e2e,
  700: #2e2e2e,
  800: #2e2e2e,
  900: #2e2e2e,
  A100: #2e2e2e,
  A200: #2e2e2e,
  A400: #2e2e2e,
  A700: #2e2e2e,
  contrast: $contrast,
);
$app-primary: mat.define-palette($parsec-darkblue);
$app-accent: mat.define-palette($parsec-lightblue);

// The warn palette is optional (defaults to red).
$app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$app-theme: mat.define-light-theme($app-primary, $app-accent, $app-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-legacy-component-themes($app-theme);

// Define an alternate dark theme.
$dark-primary: mat.define-palette($parsec-darkgrey);
$dark-accent: mat.define-palette($parsec-lightblue);
$dark-theme: mat.define-dark-theme($dark-primary, $dark-accent, $app-warn);

// Include the alternative theme styles inside of a block with a CSS class. You can make this
// CSS class whatever you want. In this example, any component inside of an element with
// `.unicorn-dark-theme` will be affected by this alternate dark theme instead of the default theme.
.dark-theme {
  @include mat.all-legacy-component-themes($dark-theme);
}

// Disable mat-stepper header navigation
.mat-horizontal-stepper-header {
  pointer-events: none !important;
}

.mat-raised-button {
  font-weight: bold;
}

.mat-form-field.fit-to-content {
  .mat-form-field-wrapper {
    width: auto !important;
  }

  .mat-select-value {
    max-width: 100%;
    width: auto;
  }
}

.mat-form-field.no-underline {
  .mat-form-field-underline {
    display: none;
  }
}

.mat-tab-label {
  font-weight: bold;
}

.mat-tab-group.mat-background-accent .mat-ink-bar {
  background-color: #121d43;
}

.mat-tab-group.mat-background-accent .mat-tab-label-active {
  background-color: #006eff;
  color: white;
  opacity: 1;
}

.rounded-top .mat-tab-header {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.mat-badge-icon .mat-badge-content {
  font-family: 'Material Icons';
}

.mat-badge-on-border-radius .mat-badge-content {
  right: -0.75em !important;
  top: -0.75em !important;
}
