/*
    Flaticon icon font: Flaticon
    Creation date: 14/06/2019 10:24
    */

@font-face {
  font-family: 'Flaticon';
  src: url('./Flaticon.eot');
  src:
    url('./Flaticon.eot?#iefix') format('embedded-opentype'),
    url('./Flaticon.woff2') format('woff2'),
    url('./Flaticon.woff') format('woff'),
    url('./Flaticon.ttf') format('truetype'),
    url('./Flaticon.svg#Flaticon') format('svg');
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: 'Flaticon';
    src: url('./Flaticon.svg#Flaticon') format('svg');
  }
}

.fi:before {
  display: inline-block;
  font-family: 'Flaticon';
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.flaticon-windows:before {
  content: '\f100';
}
.flaticon-linux:before {
  content: '\f101';
}

$font-Flaticon-windows: '\f100';
$font-Flaticon-linux: '\f101';
